import { ReporteInventarioCriticidad } from '../../types';
import { FieldTypes, WebEntity } from '../types';
import { formatStringNumber } from '../../utils/utils';

const ReporteInventarioCriticidadEntity: WebEntity<ReporteInventarioCriticidad> = {
  name: 'reporteInventarioCriticidad',
  endpoint: 'reportes',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'Id',
      selector: '_id',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Zona',
      selector: 'zona',
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: 'Nombre Centro',
      selector: 'nombreCentro',
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: 'CPCH',
      selector: 'cpch',
      columnType: 'numeric',
      format: ({ cpch }) => formatStringNumber(cpch, 0, 0),
      wrap: true,
      sortable: true
    },
    {
      name: 'VSPT',
      selector: 'vspt',
      columnType: 'numeric',
      format: ({ vspt }) => formatStringNumber(vspt, 0, 0),
      wrap: true,
      sortable: true
    },
    {
      name: 'CCU Chile',
      selector: 'ccuChile',
      columnType: 'numeric',
      format: ({ ccuChile }) => formatStringNumber(ccuChile, 0, 0),
      sortable: true
    },
    {
      name: 'ECOM CCU',
      selector: 'ecomccu',
      columnType: 'numeric',
      format: ({ ecomccu }) => formatStringNumber(ecomccu, 0, 0),
      sortable: true
    },
    {
      name: 'ECCUSA',
      selector: 'eccusa',
      columnType: 'numeric',
      format: ({ eccusa }) => formatStringNumber(eccusa, 0, 0),
      sortable: true
    },
    {
      name: 'Aguas CCU Nestle',
      selector: 'aguasCcuNestle',
      columnType: 'numeric',
      format: ({ aguasCcuNestle }) => formatStringNumber(aguasCcuNestle, 0, 0),
      sortable: true
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [
    {
      name: 'Fecha Inventario',
      selector: 'fechaInventario',
      required: true,
      type: FieldTypes.Date,
      timezone: 'America/Santiago'
    }
  ]
};

export default ReporteInventarioCriticidadEntity;
