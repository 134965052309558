import { PerfilEnvejecimiento } from '../types';
import { FieldTypes, WebEntity } from './types';
import { booleanFormatFn, formatStringNumber } from '../utils/utils';

const PerfilEnvejecimientoEntity: WebEntity<PerfilEnvejecimiento> = {
  name: 'perfilEnvejecimiento',
  endpoint: 'perfilEnvejecimiento',
  referenceColumn: 'id',

  tableColumns: [
    {
      name: 'ID',
      selector: 'id',
      columnType: 'text'
    },
    {
      name: 'Material',
      selector: 'material',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Numero Antiguo Material',
      selector: 'numeroAntiguoMaterial',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Descripcion',
      selector: 'descripcion',
      columnType: 'text',
      /*conditionalCellStyles: [ TODO: 06-11-23
        {
          when: (row) => row,
          style: {
            width: '120rem'
          }
        }
      ],*/
      wrap: true
    },
    {
      name: 'Vence',
      selector: 'vence',
      columnType: 'text',
      valueToExport: ({ vence }) => booleanFormatFn(vence),
      format: ({ vence }) => booleanFormatFn(vence)
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Duracion Total Conservacion',
      selector: 'duracionTotalConservacion',
      columnType: 'numeric',
      format: ({ duracionTotalConservacion }) =>
        formatStringNumber(duracionTotalConservacion, 0, 0),
      wrap: true
    }
  ],

  fields: [
    {
      name: 'material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Numero Antiguo Material',
      selector: 'numeroAntiguoMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'descripcion',
      selector: 'descripcion',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Vence',
      selector: 'vence',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Duracion Total Conservacion',
      selector: 'duracionTotalConservacion',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Numero Antiguo Material',
      selector: 'numeroAntiguoMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'descripcion',
      selector: 'descripcion',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Vence',
      selector: 'vence',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Duracion Total Conservacion',
      selector: 'duracionTotalConservacion',
      type: FieldTypes.Text,
      required: true
    }
  ],
  filterFields: [
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text
    },
    {
      name: 'Numero Antiguo Material',
      selector: 'numeroAntiguoMaterial',
      type: FieldTypes.Text
    },
    {
      name: 'Descripcion',
      selector: 'descripcion',
      type: FieldTypes.Text
    },
    {
      name: 'Vence',
      selector: 'vence',
      type: FieldTypes.Reference,
      reference: {
        name: 'vence',
        select: 'value',
        show: 'option',
        data: [
          { option: 'SI', value: 'true' },
          { option: 'NO', value: 'false' }
        ]
      }
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      type: FieldTypes.Text
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text
    },
    {
      name: 'Duracion Total Conservacion',
      selector: 'duracionTotalConservacion',
      type: FieldTypes.Text
    }
  ]
};

/**
 * CSV LOAD DATA CONFIGURATION
 **/

export const PE_COLUMNS = {
  material: 'Material',
  numeroAntiguoMaterial: { name: 'Numero Antiguo Material', required: false, export: true },
  descripcion: 'Descripcion',
  vence: 'Vence',
  unidadMedidaBase: 'Unidad Medida Base',
  grupoArticulos: 'Grupo Articulos',
  duracionTotalConservacion: 'Duracion Total Conservacion'
};

export default PerfilEnvejecimientoEntity;
