import { ReporteZonaCategoriaSKU } from '../../types';
import { FieldTypes, WebEntity } from '../types';
import { formatStringNumber } from '../../utils/utils';
import { updateFechaInventarioDependency } from './sharedComponents';

const ReporteCategoriaEntity: WebEntity<ReporteZonaCategoriaSKU> = {
  name: 'reporteZonaCategoriaSKU',
  endpoint: 'reportes',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'Id',
      selector: '_id',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Orden',
      selector: 'orden',
      columnType: 'text',
      omitExport: true,
      wrap: true,
      omit: true
    },
    {
      name: 'Grupo Producto',
      selector: 'grupoProducto',
      columnType: 'text',
      valueToExport: ({ grupoProducto, orden }) =>
        grupoProducto || (orden > 0 ? 'TOTAL GENERAL' : ''),
      format: ({ grupoProducto, orden }) => grupoProducto || (orden > 0 ? 'TOTAL GENERAL' : ''),
      wrap: true,
      sortable: true
    },
    {
      name: 'Tipo Producto',
      selector: 'tipoProducto',
      columnType: 'text',
      valueToExport: ({ tipoProducto, orden }) =>
        tipoProducto || (orden > 0 ? 'TOTAL GENERAL' : ''),
      format: ({ tipoProducto, orden }) => tipoProducto || (orden > 0 ? 'TOTAL GENERAL' : ''),
      wrap: true,
      sortable: true
    },
    {
      name: 'Zona',
      selector: 'zona',
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: '<21 dias',
      selector: 'menor_21Dias',
      columnType: 'numeric',
      format: ({ menor_21Dias }) => formatStringNumber(menor_21Dias, 0, 0),
      wrap: true,
      sortable: true
    },
    {
      name: '<41 dias',
      selector: 'menor_41Dias',
      columnType: 'numeric',
      format: ({ menor_41Dias }) => formatStringNumber(menor_41Dias, 0, 0),
      wrap: true,
      sortable: true
    },
    {
      name: '<61 dias',
      selector: 'menor_61Dias',
      columnType: 'numeric',
      format: ({ menor_61Dias }) => formatStringNumber(menor_61Dias, 0, 0),
      wrap: true,
      sortable: true
    },
    {
      name: '<121 dias',
      selector: 'menor_121Dias',
      columnType: 'numeric',
      format: ({ menor_121Dias }) => formatStringNumber(menor_121Dias, 0, 0),
      wrap: true,
      sortable: true
    },
    {
      name: '>121 dias',
      selector: 'mayor_121Dias',
      columnType: 'numeric',
      format: ({ mayor_121Dias }) => formatStringNumber(mayor_121Dias, 0, 0),
      wrap: true,
      sortable: true
    },
    {
      name: 'Total General',
      selector: 'totalGeneral',
      columnType: 'numeric',
      format: ({ totalGeneral }) => formatStringNumber(totalGeneral, 0, 0),
      wrap: true,
      sortable: true
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [
    {
      name: 'Estado',
      selector: 'estado',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'estado',
        select: 'estado',
        show: 'nombre',
        data: [
          {
            estado: 'vencido',
            nombre: 'VENCIDO'
          },
          {
            estado: 'vencera',
            nombre: 'VENCERA'
          },
          {
            estado: 'todos',
            nombre: 'TODOS'
          }
        ]
      }
    },
    {
      name: 'Fecha Inventario',
      selector: 'fechaInventario',
      required: true,
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      updateDependency: updateFechaInventarioDependency
    },
    {
      name: 'Numero Antiguo Material',
      selector: 'numeroAntiguoMaterial',
      type: FieldTypes.MultiReference,
      required: false,
      reference: {
        name: 'numeroAntiguoMaterial',
        endpoint: 'reportes/filtros/numeroAntiguoMaterial',
        select: 'sku',
        show: 'sku'
      }
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'textoBreveMaterial',
        endpoint: 'reportes/filtros/textoBreveMaterial',
        select: 'descripcionProducto',
        show: 'descripcionProducto'
      }
    },
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'material',
        endpoint: 'reportes/filtros/=FiltroMaterial=',
        select: 'material',
        show: 'material'
      }
    },
    {
      name: 'Zona de Transporte',
      selector: 'zonaDeTransporte',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'zonaDeTransporte',
        endpoint: 'reportes/filtros/=FiltroZona=',
        select: 'zonaDeTransporte',
        show: 'zonaDeTransporte'
      }
    },
    {
      name: 'Zonas',
      selector: 'zona',
      required: false,
      type: FieldTypes.MultiReference,
      reference: {
        name: 'zona',
        endpoint: 'reportes/filtros/zona',
        select: 'zona',
        show: 'zona'
      }
    },
    {
      name: 'Vence',
      selector: 'vencePerfilEnvejecimiento',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'vencePerfilEnvejecimiento',
        select: 'value',
        show: 'option',
        data: [
          { option: 'SI', value: 'SI' },
          { option: 'NO', value: 'NO' }
        ]
      }
    }
  ]
};

export const filterFieldReporteCategoria = (nombreConteo: 'conteoMonto' | 'conteoCantidad') => {
  return ReporteCategoriaEntity.filterFields?.map((filter) => {
    if (filter.selector === 'material') {
      const endpoint =
        nombreConteo === 'conteoMonto' ? 'materialConteoMonto' : 'materialConteoCantidad';
      return {
        ...filter,
        reference: {
          ...filter.reference,
          endpoint: (filter.reference?.endpoint as string).replace('=FiltroMaterial=', endpoint)
        }
      };
    } else if (filter.selector === 'zonaDeTransporte') {
      const endpoint =
        nombreConteo === 'conteoMonto'
          ? 'zonaDeTransporteConteoMonto'
          : 'zonaDeTransporteConteoCantidad';
      return {
        ...filter,
        reference: {
          ...filter.reference,
          endpoint: (filter.reference?.endpoint as string).replace('=FiltroZona=', endpoint)
        }
      };
    } else {
      return filter;
    }
  });
};

export default ReporteCategoriaEntity;
