import { Nomenclatura } from '../types';
import { FieldTypes, WebEntity } from './types';
import { booleanFormatFn } from '../utils/utils';

const NomenclaturaEntity: WebEntity<Nomenclatura> = {
  name: 'nomenclatura',
  endpoint: 'nomenclaturas',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: 'idNomenclatura',
      columnType: 'text'
    },
    {
      name: 'Tipo Activo',
      selector: 'descripcionTipoActivo',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Estado',
      selector: 'descripcionNomenclatura',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Agregar Casillero',
      selector: 'agregarCasillero',
      columnType: 'text',
      valueToExport: ({ agregarCasillero }) => booleanFormatFn(agregarCasillero),
      format: ({ agregarCasillero }) => booleanFormatFn(agregarCasillero)
    },
    {
      name: 'Orden Carga Manual',
      selector: 'ordenCargaManual',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Tipo Activo',
      selector: 'idTipoActivo',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'tipoUsuario',
        endpoint: 'tipoActivos',
        select: '_id',
        show: 'descripcionTipoActivo',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Estado',
      selector: 'descripcionNomenclatura',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Agregar Casillero',
      selector: 'agregarCasillero',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: 'Orden Carga Manual',
      selector: 'ordenCargaManual',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'ID Nomenclatura',
      selector: 'idNomenclatura',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Tipo Activo',
      selector: 'idTipoActivo',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'tipoUsuario',
        endpoint: 'tipoActivos',
        select: '_id',
        show: 'descripcionTipoActivo',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Estado',
      selector: 'descripcionNomenclatura',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Agregar Casillero',
      selector: 'agregarCasillero',
      type: FieldTypes.Boolean,
      required: true
    },
    {
      name: 'Orden Carga Manual',
      selector: 'ordenCargaManual',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default NomenclaturaEntity;
