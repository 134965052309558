import { Sku } from '../types';
import { FieldTypes, WebEntity } from './types';
import { dateFormatFn, formatStringNumber } from '../utils/utils';

const SKUEntity: WebEntity<Sku> = {
  name: 'sku',
  endpoint: 'sku',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: 'id',
      columnType: 'text'
    },
    {
      name: 'Material',
      selector: 'material',
      columnType: 'text',
      /*conditionalCellStyles: [ TODO: 06-11-23
        {
          when: (row) => row,
          style: {
            width: '120rem'
          }
        }
      ],*/
      wrap: true
    },
    {
      name: 'Centro',
      selector: 'centro',
      columnType: 'text'
    },
    {
      name: 'Clase Valoracion',
      selector: 'claseValoracion',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Ultima Modificacion',
      selector: 'ultimaModificacion',
      columnType: 'dateTime',
      format: ({ ultimaModificacion: d }) => dateFormatFn(d),
      wrap: true
    },
    {
      name: 'Tipo Material',
      selector: 'tipoMaterial',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Grupo Compra',
      selector: 'grupoCompra',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Indicador ABC',
      selector: 'indicadorAbc',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Caracteristica Planificacion Nec',
      selector: 'caracteristicaPlanificacionNec',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Categoria Valoracion',
      selector: 'categoriaValoracion',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Control Precio',
      selector: 'controlPrecio',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Precio',
      selector: 'precio',
      columnType: 'numeric',
      format: ({ precio }) => formatStringNumber(precio),
      wrap: true
    },
    {
      name: 'Moneda',
      selector: 'moneda',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Cantidad Base',
      selector: 'cantidadBase',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Creado Por',
      selector: 'creadoPor',
      columnType: 'text',
      wrap: true
    }
  ],

  fields: [
    {
      name: 'material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'centro',
      selector: 'centro',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Clase Valoracion',
      selector: 'claseValoracion',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tipo Material',
      selector: 'tipoMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Compra',
      selector: 'grupoCompra',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Indicador ABC',
      selector: 'indicadorAbc',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Caracteristica Planificacion NEC',
      selector: 'caracteristicaPlanificacionNec',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Categoria Valoracion',
      selector: 'categoriaValoracion',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Control Precio',
      selector: 'controlPrecio',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'precio',
      selector: 'precio',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'moneda',
      selector: 'moneda',
      type: FieldTypes.Text,
      required: false
    },
    {
      name: 'Cantidad Base',
      selector: 'cantidadBase',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Creado Por',
      selector: 'creadoPor',
      type: FieldTypes.Text,
      required: false
    }
  ],

  editableFields: [
    {
      name: 'material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'centro',
      selector: 'centro',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Clase Valoracion',
      selector: 'claseValoracion',
      type: FieldTypes.Text
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tipo Material',
      selector: 'tipoMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Compra',
      selector: 'grupoCompra',
      type: FieldTypes.Text
    },
    {
      name: 'Indicador ABC',
      selector: 'indicadorAbc',
      type: FieldTypes.Text
    },
    {
      name: 'Caracteristica Planificacion NEC',
      selector: 'caracteristicaPlanificacionNec',
      type: FieldTypes.Text
    },
    {
      name: 'Categoria Valoracion',
      selector: 'categoriaValoracion',
      type: FieldTypes.Text
    },
    {
      name: 'Control Precio',
      selector: 'controlPrecio',
      type: FieldTypes.Text
    },
    {
      name: 'precio',
      selector: 'precio',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'moneda',
      selector: 'moneda',
      type: FieldTypes.Text
    },
    {
      name: 'Cantidad Base',
      selector: 'cantidadBase',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Creado Por',
      selector: 'creadoPor',
      type: FieldTypes.Text,
      required: true
    }
  ],
  filterFields: [
    {
      name: 'material',
      selector: 'material',
      type: FieldTypes.Text
    },
    {
      name: 'centro',
      selector: 'centro',
      type: FieldTypes.Text
    },
    {
      name: 'Clase Valoracion',
      selector: 'claseValoracion',
      type: FieldTypes.Text
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Text
    },
    {
      name: 'Tipo Material',
      selector: 'tipoMaterial',
      type: FieldTypes.Text
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      type: FieldTypes.Text
    },
    {
      name: 'Grupo Compra',
      selector: 'grupoCompra',
      type: FieldTypes.Text
    },
    {
      name: 'Indicador ABC',
      selector: 'indicadorAbc',
      type: FieldTypes.Text
    },
    {
      name: 'Caracteristica Planificacion NEC',
      selector: 'caracteristicaPlanificacionNec',
      type: FieldTypes.Text
    },
    {
      name: 'Categoria Valoracion',
      selector: 'categoriaValoracion',
      type: FieldTypes.Text
    },
    {
      name: 'Control Precio',
      selector: 'controlPrecio',
      type: FieldTypes.Text
    },
    {
      name: 'precio',
      selector: 'precio',
      type: FieldTypes.Text
    },
    {
      name: 'moneda',
      selector: 'moneda',
      type: FieldTypes.Text
    },
    {
      name: 'Cantidad Base',
      selector: 'cantidadBase',
      type: FieldTypes.Text
    },
    {
      name: 'Creado Por',
      selector: 'creadoPor',
      type: FieldTypes.Text
    }
  ]
};

/**
 * CSV LOAD DATA CONFIGURATION
 **/

// deafult 'key': {'name':'name','required':true,'export':true}
export const SKU_COLUMNS = {
  material: 'Material',
  centro: 'Centro',
  claseValoracion: { name: 'Clase Valoracion', required: false, export: true },
  textoBreveMaterial: 'Texto Breve Material',
  ultimaModificacion: { name: 'Ultima modificacion', required: false, export: true },
  tipoMaterial: 'Tipo Material',
  grupoArticulos: 'Grupo Articulos',
  unidadMedidaBase: 'Unidad Medida Base',
  grupoCompra: { name: 'Grupo Compra', required: false, export: true },
  indicadorAbc: { name: 'Indicador ABC', required: false, export: true },
  caracteristicaPlanificacionNec: {
    name: 'Caracteristica Planificacion Nec',
    required: false,
    export: true
  },
  categoriaValoracion: { name: 'Categoria Valoracion', required: false, export: true },
  controlPrecio: { name: 'Control Precio', required: false, export: true },
  precio: 'Precio',
  moneda: { name: 'Moneda', required: false, export: true },
  cantidadBase: 'Cantidad Base',
  creadoPor: 'Creado Por'
};

export default SKUEntity;
