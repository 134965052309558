import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Label } from 'semantic-ui-react';
import {
  ingresarInventario,
  fetchDocumentInventarioCriticidad,
  ProcessProgressData
} from '../actions/middleActions';
import { SingleEntityState } from '../reducers/types';
import { convertArrayOfObjectToFieldsMatrix, downloadXLSX } from '../utils/export';
import { getEntity, performUncatchedRequest, getUncatchEndpointData } from '../utils/request';
import {
  ReporteCategoriaConteoCantidad,
  ReporteCategoriaConteoMonto,
  ReporteZonaConteoCantidad,
  ReporteSKUCantidad,
  ReporteSKUMonto,
  ReporteVencimientoConteoCantidad,
  ReporteVencimientoConteoMonto,
  ReporteInventarioCriticidad,
  ReporteConteoCriticidadLog,
  ReporteInconsistenciaFechaLog
} from '../components/GeneratedComponents';
import { Documento, IdTipoDocumentoValues, SimpleObject } from '../types';
import { DateTimePickerComponent } from '../components/generics/GenericForm';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { AppState } from '../store';
import { requestErrorPopAlert, warningPopAlert } from '../components/PopAlert';
import {
  InventarioCriticidadDependent,
  DocumentoCriticidadVisualizer
} from '../components/GeneratedComponents';
import { booleanFormatFn, cleanTextDate, fusionFields } from '../utils/utils';
import { AuthState } from '../reducers/types';
import { TipoUsuarioValues } from '../types';
import FileReaderCSV from '../components/FileReader';
import { Inventario } from '../types';
import {
  RefreshConteoCriticidadLogButton,
  RefreshReporteInconsistenciaFechaLogButton
} from '../components/SecondaryButton';
import { NameConteoValues } from '../types';
import GenericModal from '../components/generics/GenericModal';
import {
  CCUThemeButtonCargarConteo,
  CCUThemeButtonCargarInventario
} from '../components/generics/GenericThemes';
import Swal from 'sweetalert2';
import { AxiosResponse } from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import * as E from '../entities';
import { ColumnType } from '../entities/types';

const COLUMNS = {
  idDocumento: { name: 'Id documento', required: false, export: true },
  bodega: 'Bodega',
  idCentro: 'Centro',
  almacen: 'Almacen',
  material: 'Material',
  lote: { name: 'Lote', required: false, export: true },
  fechaRegistro: 'Fecha registro',
  fechaProduccion: 'Fecha produccion',
  fechaVencimiento: 'Fecha vencimiento',
  cantidadContada: 'Cantidad contada',
  unidadCantidadContada: 'Unidad cantidad contada',
  tipoStock: { name: 'Tipo stock', required: false, export: true },
  pedidoCliente: { name: 'Pedido cliente', required: false, export: true },
  numeroHu: { name: 'Numero hu', required: false, export: true },
  usuario: { name: 'Usuario', required: false, export: true }
};

const exportableReportColumns: Array<{
  name: string;
  selector: string;
  columnType: ColumnType;
  valueToExport?: (row: any) => string;
}> = [
  { name: 'Sku Venta', selector: 'numeroAntiguoMaterial', columnType: 'text' },
  { name: 'Marca', selector: 'marca', columnType: 'text' },
  { name: 'Stock Total', selector: 'cantidadContada', columnType: 'numeric' },
  { name: 'Descr Producto', selector: 'textoBreveMaterial', columnType: 'text' },
  { name: 'Fecha Inv', selector: 'fechaInventario', columnType: 'dateUTC' },
  { name: 'Fecha Elaboración', selector: 'fechaElaboracion', columnType: 'dateUTC' },
  { name: 'SKU Inventario', selector: 'material', columnType: 'text' },
  { name: 'Centro', selector: 'nombreCentro', columnType: 'text' },
  { name: 'Zona', selector: 'zona', columnType: 'text' },
  { name: 'Fecha_Venc', selector: 'fechaVencimiento', columnType: 'dateUTC' },
  { name: 'Periodo a Vencer', selector: 'periodoVencer', columnType: 'text' },
  { name: 'Vida Útil', selector: 'vidaUtil', columnType: 'numeric' },
  { name: 'Días Por Vencer', selector: 'diasPorVencer', columnType: 'numeric' },
  { name: 'Dias desde Elaboracion', selector: 'diasDesdeElaboracion', columnType: 'numeric' },
  { name: 'Vencido?', selector: 'estadoVencido', columnType: 'text' },
  { name: 'VU Restante', selector: 'vuRestante', columnType: 'percentage' },
  { name: 'Categoria Conteo', selector: 'categoriaConteo', columnType: 'text' },
  { name: 'DESCR_GRUPO_PROD', selector: 'grupoProducto', columnType: 'text' },
  { name: 'Categoría', selector: 'tipoProducto', columnType: 'text' },
  { name: 'COSTO', selector: 'costoUnitario', columnType: 'numeric' },
  { name: 'Factor', selector: 'factor', columnType: 'numeric' },
  { name: 'Monto Final', selector: 'montoFinal', columnType: 'numeric' },
  { name: 'Cantidad por vencer', selector: 'cantidadVencer', columnType: 'numeric' },
  { name: 'Prom Venta', selector: 'promoVentaMes', columnType: 'numeric' },
  { name: 'Monto Final Vencer', selector: 'montoFinalVencer', columnType: 'numeric' },
  { name: 'Vencera?', selector: 'vencera', columnType: 'text' },
  {
    name: 'Vence PerfilEnvejecimiento',
    selector: 'vencePerfilEnvejecimiento',
    columnType: 'text',
    valueToExport: ({ vencePerfilEnvejecimiento }) => booleanFormatFn(vencePerfilEnvejecimiento)
  },
  { name: 'ID Centro', selector: 'idCentro', columnType: 'text' },
  { name: 'Id Documento', selector: 'idDocumento', columnType: 'text' },
  { name: 'Comentario', selector: 'comentario', columnType: 'text' }
];

type Props = {
  documentoCriticidadState: SingleEntityState<Documento>;
  fetchDocumentInventarioCriticidad: any;
};

interface IProps {
  auth: AuthState;
}

const RefresherComponent: FunctionComponent<Props> = ({
  documentoCriticidadState,
  fetchDocumentInventarioCriticidad
}): any => {
  useEffect(() => {
    const update = async () => {
      if (documentoCriticidadState.singleSelected?._id) {
        await fetchDocumentInventarioCriticidad(documentoCriticidadState.singleSelected?._id);
      }
    };
    update();
  }, [documentoCriticidadState.singleSelected?._id, fetchDocumentInventarioCriticidad]);

  return (
    <h2 className='title-DataTable'> DOCUMENTO {documentoCriticidadState.singleSelected?._id} </h2>
  );
};

export const ConnectedRefresh = connect(
  ({ entities: { documentoCriticidad } }: AppState) => ({
    documentoCriticidadState: documentoCriticidad
  }),
  {
    fetchDocumentInventarioCriticidad
  }
)(RefresherComponent);

const GenericDropDownList: FunctionComponent<{
  value: string | string[];
  fieldName: string;
  placeholder: string;
  data: any[];
  valueField: string;
  textField: string;
  loading: boolean;
  onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  multiple: boolean;
}> = ({
  value,
  fieldName,
  placeholder,
  data,
  valueField,
  textField,
  loading,
  onChange,
  multiple
}) => {
  const options = React.useMemo(
    () =>
      data && data.length > 0
        ? data.map((opt, idx) => ({
            key: `${fieldName}_${idx}`,
            value: opt[valueField],
            text: fusionFields(textField)(opt)
          }))
        : [],
    [data]
  );

  return (
    <Dropdown
      {...{
        className: 'mb-3',
        placeholder,
        fluid: true,
        lazyLoad: true,
        search: true,
        selection: true,
        clearable: true,
        options,
        onChange,
        value,
        loading,
        disabled: loading,
        multiple
      }}
    />
  );
};

const GenerarConteo: FunctionComponent<{ auth: AuthState }> = ({ auth }) => {
  const [initialDate, setInitialDate] = useState<string | null>(cleanTextDate(new Date()));
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [isExporting, setIsExporting] = useState<boolean | null>(null);
  const [filterZones, setFilterZones] = useState<string[]>([]);
  const [selectedFilterZones, setSelectedFilterZones] = useState<string[]>([]);
  const [filterSku, setFilterSku] = useState<string[]>([]);
  const [selectedFilterSku, setSelectedFilterSku] = useState<string[]>([]);
  const [filterTipoProducto, setFilterTipoProducto] = useState<string[]>([]);
  const [selectedFilterTipoProducto, setSelectedFilterTipoProducto] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onDescargarReporteClick = (initialDate: string | null) => async () => {
    try {
      if (!initialDate) {
        Swal.fire({
          icon: 'error',
          title: 'Debes seleccionar una fecha de inicio'
        });
        return;
      }

      setIsExporting(true);
      const res = await getUncatchEndpointData({
        endpoint: 'inventarioCriticidad/export/reporte',
        query: {
          initialDate,
          nombreCentro: selectedFilterZones,
          material: selectedFilterSku,
          tipoProducto: selectedFilterTipoProducto
        }
      });

      if (res.length === 0) {
        setIsExporting(false);
        return warningPopAlert(`No se encontraron registros que exportar`);
      }

      const data = convertArrayOfObjectToFieldsMatrix(
        res.map((row: any) =>
          exportableReportColumns.reduce((acc, obj) => {
            if (row.hasOwnProperty(obj.selector)) {
              const key = obj.selector as string;
              acc[key] = obj.valueToExport ? obj.valueToExport(row) : row[key];
            }
            return acc;
          }, {} as Record<string, any>)
        ),
        exportableReportColumns.map((c) => c.selector),
        exportableReportColumns.map((c) => c.name)
      );

      downloadXLSX(
        data,
        exportableReportColumns.map((c) => c.columnType),
        `Reporte-Conteo-Criticidad_${new Date().toISOString().split('T')[0]}.xlsx`
      );

      setIsExporting(false);
    } catch (error) {
      console.log(error);
      requestErrorPopAlert(error);
      setIsExporting(false);
    }
  };

  const onGenerarConteoClick = (initialDate: string | null) => async () => {
    try {
      if (!initialDate) {
        Swal.fire({
          icon: 'error',
          title: 'Debes seleccionar una fecha de inicio'
        });
        return;
      }

      setIsLoading(true);

      await performUncatchedRequest({
        endpoint: 'inventarioCriticidad/generarConteo',
        method: 'post',
        query: { fechaConteo: initialDate }
      });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      requestErrorPopAlert(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setFilterZones(
        await getEntity('reportes/getFilterZones', {
          query: { idTipoDocumento: IdTipoDocumentoValues.idConteoCriticidad }
        })
      );
      setFilterSku(
        await getEntity('reportes/getFilterSku', {
          query: { idTipoDocumento: IdTipoDocumentoValues.idConteoCriticidad }
        })
      );
      setFilterTipoProducto(
        await getEntity('reportes/getFilterTipoProducto', {
          query: { idTipoDocumento: IdTipoDocumentoValues.idConteoCriticidad }
        })
      );
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <div className='pop-up-container'>
        <div className='pop-up-container-title'>
          <h3 className='pop-up-generar-conteo-title'>
            <strong className='pop-up-generar-conteo-title-strong'>REPORTE</strong>
          </h3>
        </div>
        <div className='pop-up-container-date'>
          <label className='pop-up-date-title' style={{ width: '100%' }}>
            Fecha Conteo
          </label>
          <DateTimePickerComponent
            name={'Fecha Inicio'}
            value={initialDate}
            onChange={setInitialDate}
            format='dd/MM/yyyy'
            timezone='America/Santiago'
          />

          {(auth.user?.nombreTipoUsuario === TipoUsuarioValues.Admin ||
            auth.user?.nombreTipoUsuario === TipoUsuarioValues.Visualizador) && (
            <>
              <label className='pop-up-date-title' style={{ width: '100%' }}>
                Zona
              </label>
              <GenericDropDownList
                placeholder={'Seleccione Zonas'}
                fieldName={'nombreCentro'}
                data={filterZones}
                onChange={(_: any, data: any) => setSelectedFilterZones(data.value)}
                valueField={'nombreCentro'}
                textField={'nombreCentro'}
                value={selectedFilterZones}
                loading={loading}
                multiple={true}
              />
              <label className='pop-up-date-title' style={{ width: '100%' }}>
                SKU
              </label>
              <GenericDropDownList
                placeholder={'Seleccione Sku'}
                fieldName={'material'}
                data={filterSku}
                onChange={(_: any, data: any) => setSelectedFilterSku(data.value)}
                valueField={'material'}
                textField={'material'}
                value={selectedFilterSku}
                loading={loading}
                multiple={true}
              />
              <label className='pop-up-date-title' style={{ width: '100%' }}>
                Agrupación producto
              </label>
              <GenericDropDownList
                placeholder={'Seleccione Agrupación producto'}
                fieldName={'tipoProducto'}
                data={filterTipoProducto}
                onChange={(_: any, data: any) => setSelectedFilterTipoProducto(data.value)}
                valueField={'tipoProducto'}
                textField={'tipoProducto'}
                value={selectedFilterTipoProducto}
                loading={loading}
                multiple={true}
              />
            </>
          )}
        </div>

        <button className='btn-pop-up-generic' onClick={onGenerarConteoClick(initialDate)}>
          Generar Reporte
        </button>
        <button className='btn-pop-up-generic' onClick={onDescargarReporteClick(initialDate)}>
          Exportar Conteo Criticidad
        </button>
        {isLoading === true && <span className='loader'></span>}
        {isLoading === false && <h1>Generado!</h1>}
        {isExporting === true && <h1>Exportando...</h1>}
        {/* {isLoading === false && resultGenerateConteo && <LogText>{resultGenerateConteo}</LogText>} */}
      </div>
    </div>
  );
};

const WelcomePage: FunctionComponent<
  IProps & {
    ingresarInventario: (
      inventarioEntity: SimpleObject,
      documentoEntity: SimpleObject,
      inventarioCriticidad: Array<Inventario>,
      date: Date,
      zona: string,
      callback: (res: AxiosResponse<ProcessProgressData> | null) => void,
      idComentario: string
    ) => Promise<any>;
  }
> = ({ ingresarInventario, auth }) => {
  const [date, setDate] = useState<string | null>(cleanTextDate(new Date()));
  const [comentarios, setComentarios] = useState<SimpleObject[]>([]);
  const [selectedComentario, setSelectedComentario] = useState<string>('');
  const [selectedZone, setSelectedZone] = useState<string>('');
  const [zones, setZones] = useState<string[]>([]);
  const { user } = auth;
  const allowedUserTypes = [
    TipoUsuarioValues.Admin as string,
    TipoUsuarioValues.Operador as string
  ];
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [progressInfo, setProgressInfo] = useState<string>('');
  const [progressPercentage, setProgressPercentage] = useState<number>(0);

  const CargaInventarioBody = () => {
    if (allowedUserTypes.includes(user?.nombreTipoUsuario || '')) {
      return (
        <div>
          <GenericModal
            title='Carga de Inventario'
            buttonStyle={CCUThemeButtonCargarInventario}
            buttonName='CARGAR CONTEO MASIVO'
            icon='update'
            localIcon={true}
            openHook={() => setOpenModal(true)}
            closeHook={() => setOpenModal(false)}
            openModal={openModal}>
            <div>
              <Label>Fecha de Recuento</Label>
              <DateTimePickerComponent
                name={'Fecha'}
                value={date}
                onChange={setDate}
                format='dd/MM/yyyy'
                timezone='America/Santiago'
              />
              <div style={{ marginLeft: '1em', display: 'inline-block' }}>
                <Label>Zona</Label>
                <div style={{ display: 'inline-block' }}>
                  <GenericDropDownList
                    placeholder={'Seleccione Zona'}
                    fieldName={'zone'}
                    data={zones}
                    onChange={onChangeZone}
                    value={selectedZone}
                    valueField={'nombreCentro'}
                    textField={'nombreCentro'}
                    loading={loading}
                    multiple={false}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Label>Archivo CSV</Label>
                  </div>
                  <div>
                    <Label>Comentario</Label>
                    <div style={{ display: 'inline-block' }}>
                      <GenericDropDownList
                        placeholder={'Seleccione Comentario'}
                        fieldName={'comentario'}
                        data={comentarios}
                        onChange={onChangeComentario}
                        value={selectedComentario}
                        valueField={'comentario'}
                        textField={'comentario'}
                        loading={loading}
                        multiple={false}
                      />
                    </div>
                  </div>
                </div>
                <FileReaderCSV
                  columns={COLUMNS}
                  callback={callback(date)}
                  dataInputName={NameConteoValues.conteoCriticidad}
                />
              </div>
              {progressInfo && <h3>{progressInfo}</h3>}
              {progressPercentage !== 0 && (
                <ProgressBar
                  animated
                  variant='success'
                  now={progressPercentage}
                  label={`${progressPercentage.toFixed(2)}%`}
                  visuallyHidden
                />
              )}
            </div>
          </GenericModal>
        </div>
      );
    }
    return null;
  };

  const onChangeZone = (_: any, data: any) => {
    setSelectedZone(data.value);
  };

  const onChangeComentario = (_: any, data: any) => {
    setSelectedComentario(data.value);
  };

  const callback = (date: string | null) => async (data: any) => {
    if (!selectedZone) {
      Swal.fire({
        icon: 'error',
        title: 'Debes seleccionar una zona'
      });
      return;
    } else if (!data) {
      Swal.fire({
        icon: 'error',
        title: 'Debes cargar un documento de conteo'
      });
      return;
    } else if (!date) {
      Swal.fire({
        icon: 'error',
        title: 'Debes seleccionar una fecha'
      });
      return;
    } else if (!selectedComentario) {
      Swal.fire({
        icon: 'error',
        title: 'Debes seleccionar un comentario'
      });
      return;
    }

    setProgressInfo('Enviando datos al servidor...');
    const comentario = comentarios.find((row) => row.comentario === selectedComentario);

    await ingresarInventario(
      E.InventarioCriticidadEntity,
      E.DocumentoCriticidadEntity,
      data,
      new Date(date),
      selectedZone,
      (res: AxiosResponse<ProcessProgressData> | null) => {
        if (res === null) {
          setProgressInfo('');
          setProgressPercentage(0);
          setOpenModal(false);
          return;
        }

        if (!res.data) {
          console.log('No se pudo obtener el progresso de la carga.');
          setProgressInfo('');
          setProgressPercentage(0);
          return;
        }

        if (res.data.done) {
          console.log(res.data);
          setProgressInfo('');
          setProgressPercentage(0);

          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '¡Documento ingresado!',
              text: `Se registro con éxito el documento con ID: ${res.data?.data.idDocumento}`
            });
            setOpenModal(false);
          }
          return;
        }

        console.log(res.data);
        setProgressInfo(`${res.data.message} (${Math.round(res.data.percentage)}%)`);
        setProgressPercentage(Math.round(res.data.percentage));
      },
      comentario?.idComentario
    );
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setZones(await getEntity('centroInventario/zonas'));
      setComentarios(
        await getEntity(`comentario/tipoDocumento/${IdTipoDocumentoValues.idConteoCriticidad}`)
      );
      setLoading(false);
    })();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: 15 }}>
      <h2 className='Conteo-Criticidad-title'>
        Conteo <strong className='Conteo-Criticidad-title-strong'>Criticidad</strong>
      </h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', gap: 10 }}>
          {CargaInventarioBody()}
          <GenericModal
            buttonName='GENERAR REPORTE'
            buttonStyle={CCUThemeButtonCargarConteo}
            localIcon={true}
            icon='table'
            modalStyle={{
              width: 'auto',
              height: 'auto',
              top: 'auto',
              left: 'auto',
              overflow: 'visible'
            }}>
            <GenerarConteo auth={auth} />
          </GenericModal>
        </div>

        <div
          style={{
            backgroundColor: ' red',
            borderRadius: '5px',
            border: ' 2px solid var(--gray-5, #E0E0E0)',
            background: 'var(--blanco, #FFF)',
            boxShadow: '4px 5px 7px 0px #D5D6D7',
            padding: 5,
            width: '100%'
          }}>
          <DocumentoCriticidadVisualizer />
        </div>
        <div
          style={{
            backgroundColor: ' red',
            borderRadius: '5px',
            border: ' 2px solid var(--gray-5, #64A70B)',
            background: 'var(--blanco, #FFF)',
            boxShadow: '4px 5px 7px 0px #D5D6D7',
            padding: 5,
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <div
            style={{
              marginLeft: 20,
              marginTop: 20
            }}>
            <ConnectedRefresh />
          </div>
          <InventarioCriticidadDependent />
        </div>
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { ingresarInventario })(WelcomePage);

/* REPORTES GENERALES */

export const ReporteCategoriaConteoMontoComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteCategoriaConteoMonto />
    </div>
  );
};

export const ReporteCategoriaConteoCantidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteCategoriaConteoCantidad />
    </div>
  );
};

export const ReporteZonaConteoMontoComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteCategoriaConteoCantidad />
    </div>
  );
};

export const ReporteZonaConteoCantidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteZonaConteoCantidad />
    </div>
  );
};

export const ReporteSKUMontoComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteSKUMonto />
    </div>
  );
};
export const ReporteSKUCantidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteSKUCantidad />
    </div>
  );
};

export const ReporteVencimientoConteoMontoComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteVencimientoConteoMonto />
    </div>
  );
};

export const ReporteVencimientoConteoCantidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteVencimientoConteoCantidad />
    </div>
  );
};

export const ReporteInventarioCriticidadComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ReporteInventarioCriticidad />
    </div>
  );
};

/* REPORTES GENERALES */

/* REPORTES INCONSISTENCIAS */

export const ReporteConteoCriticidadLogComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1em' }}>
        <RefreshConteoCriticidadLogButton />
      </div>
      <ReporteConteoCriticidadLog />
    </div>
  );
};

export const ReporteInconsistenciaFechaLogComponent = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1em' }}>
        <RefreshReporteInconsistenciaFechaLogButton />
      </div>
      <ReporteInconsistenciaFechaLog />
    </div>
  );
};

/* REPORTES INCONSISTENCIAS */
