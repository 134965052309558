import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthState } from '../reducers/types';
import { TipoUsuarioValues } from '../types';
import { operadorLinks, publicLinks } from './App';

export const useUserAccess = (auth: AuthState) => {
  const history = useHistory();

  //const { trackingCode } = useParams<{ trackingCode: string | undefined }>();

  useEffect(() => {
    const { isLoading, isAuthenticated, user } = auth;

    // Admin can see all the app links
    if (isLoading || user?.nombreTipoUsuario === TipoUsuarioValues.Admin) {
      return;
    }

    // Anonimous users donesn't have links
    if (!isAuthenticated) {
      history.push(`/`);
      return;
    }

    if (
      user?.nombreTipoUsuario === TipoUsuarioValues.Operador ||
      user?.nombreTipoUsuario === TipoUsuarioValues.Visualizador
    ) {
      if (
        [...publicLinks, ...operadorLinks]
          .map(({ href }) => href)
          .includes(history.location.pathname)
      ) {
        return history.push(
          history.location.pathname === '/landing' ? '/conteo-criticidad' : history.location
        );
      }
    }

    history.push('/');
  }, [auth, history]);
};
