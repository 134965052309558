import { CentroInventario } from '../types';
import { FieldTypes, WebEntity } from './types';
import { booleanFormatFn } from '../utils/utils';

const CentroInventarioEntity: WebEntity<CentroInventario> = {
  name: 'centroInventario',
  endpoint: 'centroInventario',
  referenceColumn: 'idCentro',

  tableColumns: [
    {
      name: 'ID Centro',
      selector: '_id',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'ID Centro',
      selector: 'idCentro',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'ID Empresa',
      selector: 'idEmpresa',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Empresa',
      selector: 'empresa',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Zona',
      selector: 'zona',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Nombre',
      selector: 'nombreCentro',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Zona de Transporte',
      selector: 'zonaDeTransporte',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Centro 7Horas',
      selector: 'centroSieteHoras',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Bodega de Acopio',
      selector: 'bodegaExterna',
      columnType: 'text',
      valueToExport: ({ bodegaExterna }) => booleanFormatFn(bodegaExterna),
      format: ({ bodegaExterna }) => booleanFormatFn(bodegaExterna),
      sortable: true,
      wrap: true
    }
  ],

  fields: [
    {
      name: 'ID Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'ID Empresa',
      selector: 'idEmpresa',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Empresa',
      selector: 'empresa',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Zona',
      selector: 'zona',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre',
      selector: 'nombreCentro',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Zona de Transporte',
      selector: 'zonaDeTransporte',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Centro 7Horas',
      selector: 'centroSieteHoras',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Bodega de Acopio',
      selector: 'bodegaExterna',
      type: FieldTypes.Boolean,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'ID Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'ID Empresa',
      selector: 'idEmpresa',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Empresa',
      selector: 'empresa',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Zona',
      selector: 'zona',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Nombre',
      selector: 'nombreCentro',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Zona de Transporte',
      selector: 'zonaDeTransporte',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Centro 7Horas',
      selector: 'centroSieteHoras',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Bodega de Acopio',
      selector: 'bodegaExterna',
      type: FieldTypes.Boolean,
      required: true
    }
  ]
};

export default CentroInventarioEntity;
