import React, { useEffect, useState } from 'react';
import './GenericDropdownMenu.css';
import { useHistory, useLocation } from 'react-router-dom';
import { CURRENT_PAGE_KEY, getFromStorage, addToStorage } from '../../../utils/storage';
import GenericIcons from '../GenericIcons';

export interface IMenuItem {
  id: number;
  label: string;
  icon?: string;
  img?: string;
  href?: string;
  children?: IMenuItem[];
  isOpened?: boolean;
}

type Props = {
  menu: IMenuItem[];
  deactivateParent?: () => void;
  menuLevel?: number;
  child?: boolean;
};

const GenericDropdownMenu = ({ menu, deactivateParent, menuLevel = 1, child }: Props) => {
  const router = useHistory();
  const location = useLocation();
  const [menuItems, setMenuItems] = useState<IMenuItem[]>(menu);
  const [activeItemId, setActiveItemId] = useState<number>(0);
  const [closeAllDropdowns, setCloseAllDropdowns] = useState(false);

  const deactivateItem = () => {
    setActiveItemId(0);
  };

  const handleCloseAllDropdowns = () => {
    setCloseAllDropdowns(true);
  };

  useEffect(() => {
    const currentPageHref = getFromStorage(CURRENT_PAGE_KEY);
    if (currentPageHref) {
      const foundItem = menuItems.find((item) => item.href === currentPageHref);
      if (foundItem) {
        setActiveItemId(foundItem.id);
        foundItem.href && router.push(foundItem.href);
      }
    }
    if (closeAllDropdowns) {
      const menuUpdated = menu.map((i) => ({
        ...i,
        isOpened: false
      }));
      setMenuItems(menuUpdated);
      setCloseAllDropdowns(false);
    }
  }, [closeAllDropdowns, menu]);

  useEffect(() => {
    if (location.pathname === '/') {
      handleCloseAllDropdowns();
    }
  }, [location.pathname]);

  const toggleDropdown = (item: IMenuItem) => {
    let menuUpdated: IMenuItem[] = menu;
    if (item.children && item.children.length > 0) {
      menuUpdated = menu.map((i) => ({
        ...i,
        isOpened: item.id !== i.id ? false : item.id === i.id ? !item.isOpened : i.isOpened
      }));
      setActiveItemId(item.id);
    } else {
      if (deactivateParent) {
        deactivateParent();
      }
      menuUpdated = menu.map((i) => ({
        ...i,
        isOpened: i.children && item.id !== i.id ? false : i.isOpened
      }));
      setActiveItemId(item.id);
      addToStorage(CURRENT_PAGE_KEY, item.href || '');
      item.href && router.replace(`${item.href}`);
    }
    setMenuItems(menuUpdated);
  };

  interface SearchResult {
    item: IMenuItem;
    menu: IMenuItem[];
  }

  //TODO: only works up to one child, missing when children of children exist
  // In the same way, it is necessary to correct the design for when there are children of children
  const findItemByHref = (arr: IMenuItem[], targetHref: string): SearchResult | null => {
    const find = (items: IMenuItem[], parents: IMenuItem[] = []): SearchResult | null => {
      for (const item of items) {
        if (item.href === targetHref) {
          parents.forEach((parent) => (parent.isOpened = true));
          return { item, menu: arr };
        }

        if (item.children && item.children.length > 0) {
          const result = find(item.children, [...parents, item]);
          if (result) {
            return result;
          }
        }
      }
      return null;
    };

    return find(arr);
  };

  // tells the Sidebar which item is selected according to href
  // for when entering a url via keyboard
  const selectItemByHref = (menu: IMenuItem[]) => {
    const currentItem = findItemByHref(menu, location.pathname);
    if (currentItem) {
      toggleDropdown(currentItem.item);
      setMenuItems(currentItem.menu);
    }
  };

  useEffect(() => {
    if (menu.length > 0) {
      setMenuItems(menu);
      selectItemByHref(menu);
    }
  }, [menu]);

  useEffect(() => {
    selectItemByHref(menuItems);
  }, [location.pathname]);

  return (
    <div
      className={`generic-dropdown-menu-container ${
        child ? 'generic-dropdown-menu-container-child' : ''
      }`}>
      <ul>
        {menuItems.map((item, i) => (
          <li key={i}>
            <div
              className={`generic-dropdown-menu-item ${
                child ? 'generic-dropdown-menu-item-child' : ''
              } ${
                item.isOpened
                  ? 'generic-dropdown-menu-item-opened'
                  : item.id === activeItemId && menuLevel === 1
                  ? 'generic-dropdown-menu-item-opened'
                  : ''
              }`}
              onClick={() => toggleDropdown(item)}
              style={
                item.id === activeItemId && menuLevel !== 1
                  ? {
                      borderLeftColor: '#002e2c'
                    }
                  : {}
              }>
              {item.children && (
                <div>
                  <GenericIcons
                    icon='CaretRightIcon'
                    imageStyle={{
                      transform: item.isOpened ? 'rotate(90deg)' : 'none'
                    }}
                  />
                </div>
              )}
              {item.icon && (
                <div style={!item.children ? { marginLeft: 15 } : {}}>
                  <GenericIcons icon={item.icon} />
                </div>
              )}
              <p
                className='generic-dropdown-menu-item-label'
                style={
                  item.id === activeItemId && menuLevel !== 1
                    ? {
                        fontWeight: '700'
                      }
                    : {}
                }>
                {item.label}
              </p>
            </div>
            {item.children && item.isOpened && (
              <GenericDropdownMenu
                menu={item.children}
                deactivateParent={deactivateItem}
                menuLevel={menuLevel + 1}
                child={true}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GenericDropdownMenu;
