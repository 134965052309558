import { DiasVenta } from '../types';
import { FieldTypes, WebEntity } from './types';
import { formatStringNumber } from '../utils/utils';

const DiasVentaEntity: WebEntity<DiasVenta> = {
  name: 'diasVenta',
  endpoint: 'diasVenta',
  referenceColumn: 'id',

  /*
   * dep_dist
   * grupo_producto
   * producto
   * marca
   * promo_venta_mes
   * forecast
   * porcentaje_forecast
   **/
  tableColumns: [
    {
      name: 'ID',
      selector: 'id',
      columnType: 'text'
    },
    {
      name: 'Dep Dist',
      selector: 'depDist',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Grupo Producto',
      selector: 'grupoProducto',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Producto',
      selector: 'producto',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Promo Venta Mes',
      selector: 'promoVentaMes',
      columnType: 'numeric',
      format: ({ promoVentaMes }) => formatStringNumber(promoVentaMes, 0, 6),
      wrap: true
    },
    {
      name: 'Forecast',
      selector: 'forecast',
      columnType: 'numeric',
      format: ({ forecast }) => formatStringNumber(forecast, 0, 6),
      wrap: true
    },
    {
      name: 'Porcentaje Forecast',
      selector: 'porcentajeForecast',
      columnType: 'numeric',
      format: ({ porcentajeForecast }) => formatStringNumber(porcentajeForecast, 0, 6),
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Dep Dist',
      selector: 'depDist',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Producto',
      selector: 'grupoProducto',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'producto',
      selector: 'producto',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'marca',
      selector: 'marca',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Promo VentaMes',
      selector: 'promoVentaMes',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'forecast',
      selector: 'forecast',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Porcentaje Forecast',
      selector: 'porcentajeForecast',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Dep Dist',
      selector: 'depDist',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Producto',
      selector: 'grupoProducto',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'producto',
      selector: 'producto',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'marca',
      selector: 'marca',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Promo VentaMes',
      selector: 'promoVentaMes',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'forecast',
      selector: 'forecast',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Porcentaje Forecast',
      selector: 'porcentajeForecast',
      type: FieldTypes.Text,
      required: true
    }
  ],
  filterFields: [
    {
      name: 'Dep Dist',
      selector: 'depDist',
      type: FieldTypes.Text
    },
    {
      name: 'Grupo Producto',
      selector: 'grupoProducto',
      type: FieldTypes.Text
    },
    {
      name: 'Producto',
      selector: 'producto',
      type: FieldTypes.Text
    },
    {
      name: 'Marca',
      selector: 'marca',
      type: FieldTypes.Text
    },
    {
      name: 'Promo Venta Mes',
      selector: 'promoVentaMes',
      type: FieldTypes.Text
    },
    {
      name: 'Forecast',
      selector: 'forecast',
      type: FieldTypes.Text
    },
    {
      name: 'Porcentaje Forecast',
      selector: 'porcentajeForecast',
      type: FieldTypes.Text
    }
  ]
};

/**
 * CSV LOAD DATA CONFIGURATION
 **/

// deafult 'key': {'name':'name','required':true,'export':true}
export const DV_COLUMNS = {
  depDist: 'Dep Dist',
  grupoProducto: { name: 'Grupo Producto', required: false, export: true },
  producto: 'Producto',
  marca: { name: 'Marca', required: false, export: true },
  promoVentaMes: 'Promo Venta Mes',
  forecast: 'Forecast',
  porcentajeForecast: 'Porcentaje Forecast'
};

export default DiasVentaEntity;
