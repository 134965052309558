import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Label } from 'semantic-ui-react';
import {
  ingresarInventario,
  fetchDocumentInventarioPallet,
  ProcessProgressData
} from '../actions/middleActions';
import { SingleEntityState } from '../reducers/types';
import { downloadXLSX, convertArrayOfObjectToFieldsMatrix } from '../utils/export';
import { getEntity, performUncatchedRequest, getUncatchEndpointData } from '../utils/request';
import { Documento, IdTipoDocumentoValues, SimpleObject } from '../types';
import { DateTimePickerComponent } from '../components/generics/GenericForm';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { AppState } from '../store';
import { requestErrorPopAlert, warningPopAlert } from '../components/PopAlert';
import {
  InventarioPalletDependent,
  DocumentoPalletVisualizer
} from '../components/GeneratedComponents';
import { cleanTextDate, fusionFields } from '../utils/utils';
import { AuthState } from '../reducers/types';
import { TipoUsuarioValues } from '../types';
import FileReaderCSV from '../components/FileReader';
import { Inventario } from '../types';
import { NameConteoValues } from '../types';
import GenericModal from '../components/generics/GenericModal';
import {
  CCUThemeButtonCargarConteo,
  CCUThemeButtonCargarInventario
} from '../components/generics/GenericThemes';
import Swal from 'sweetalert2';
import { AxiosResponse } from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import * as E from '../entities';
import { ColumnType } from '../entities/types';

const COLUMNS = {
  idDocumento: { name: 'Id documento', required: false, export: true },
  bodega: 'Bodega',
  idCentro: 'Centro',
  almacen: 'Almacen',
  material: 'Material',
  lote: { name: 'Lote', required: false, export: true },
  fechaRegistro: 'Fecha registro',
  fechaProduccion: { name: 'Fecha produccion', required: false, export: true },
  fechaVencimiento: { name: 'Fecha vencimiento', required: false, export: true },
  cantidadContada: 'Cantidad contada',
  unidadCantidadContada: { name: 'Unidad cantidad contada', required: false, export: true },
  tipoStock: 'Tipo stock',
  pedidoCliente: { name: 'Pedido cliente', required: false, export: true },
  numeroHu: { name: 'Numero hu', required: false, export: true },
  usuario: { name: 'Usuario', required: false, export: true }
};

const exportableReportColumns: Array<{
  name: string;
  selector: string;
  columnType: ColumnType;
  valueToExport?: (row: any) => string;
}> = [
  { name: 'Stock Total', selector: 'cantidadContada', columnType: 'numeric' },
  { name: 'Descr Producto', selector: 'textoBreveMaterial', columnType: 'text' },
  { name: 'Fecha Inv', selector: 'fechaInventario', columnType: 'dateUTC' },
  { name: 'SKU Inventario', selector: 'material', columnType: 'text' },
  { name: 'Centro', selector: 'nombreCentro', columnType: 'text' },
  { name: 'Zona', selector: 'zona', columnType: 'text' },
  { name: 'ID Centro', selector: 'idCentro', columnType: 'text' },
  { name: 'Utilizado', selector: 'tipoStock', columnType: 'text' },
  { name: 'Grupo Articulos', selector: 'grupoArticulos', columnType: 'text' },
  { name: 'Unidad Cantidad Contada', selector: 'unidadCantidadContada', columnType: 'text' },
  { name: 'Carga Conteo', selector: 'cargaConteo', columnType: 'text' },
  { name: 'Id Documento', selector: 'idDocumento', columnType: 'text' },
  { name: 'Comentario', selector: 'comentario', columnType: 'text' }
];

const exportableColumnsConsolidatedReport: Array<{
  name: string;
  selector: string;
  columnType: ColumnType;
  valueToExport?: (row: any) => string;
}> = [
  { name: 'Id documento', selector: 'idDocumento', columnType: 'text' },
  { name: 'Bodega', selector: 'bodega', columnType: 'text' },
  { name: 'Centro', selector: 'centro', columnType: 'text' },
  { name: 'Almacen', selector: 'almacen', columnType: 'text' },
  { name: 'Material', selector: 'material', columnType: 'text' },
  { name: 'Lote', selector: 'lote', columnType: 'text' },
  { name: 'Fecha registro', selector: 'fechaRegistro', columnType: 'dateUTC' },
  { name: 'Fecha produccion', selector: 'fechaProduccion', columnType: 'text' },
  { name: 'Fecha vencimiento', selector: 'fechaVencimiento', columnType: 'text' },
  { name: 'Cantidad contada', selector: 'cantidadContada', columnType: 'numeric' },
  { name: 'Unidad cantidad contada', selector: 'unidadCantidadContada', columnType: 'text' },
  { name: 'Tipo stock', selector: 'tipoStock', columnType: 'text' },
  { name: 'Pedido cliente', selector: 'pedidoCliente', columnType: 'text' },
  { name: 'Numero hu', selector: 'numeroHu', columnType: 'text' },
  { name: 'Usuario', selector: 'usuario', columnType: 'text' }
];

type Props = {
  documentoPalletState: SingleEntityState<Documento>;
  fetchDocumentInventarioPallet: any;
};

interface IProps {
  auth: AuthState;
}

const RefresherComponent: FunctionComponent<Props> = ({
  documentoPalletState,
  fetchDocumentInventarioPallet
}) => {
  useEffect(() => {
    const update = async () => {
      if (documentoPalletState.singleSelected?._id) {
        await fetchDocumentInventarioPallet(documentoPalletState.singleSelected?._id);
      }
    };

    update();
  }, [documentoPalletState.singleSelected?._id, fetchDocumentInventarioPallet]);
  return (
    <h2 className='title-DataTable'> DOCUMENTO {documentoPalletState.singleSelected?._id} </h2>
  );
};

export const ConnectedRefreshPallet = connect(
  ({ entities: { documentoPallet } }: AppState) => ({ documentoPalletState: documentoPallet }),
  {
    fetchDocumentInventarioPallet
  }
)(RefresherComponent);

const GenericDropDownList: FunctionComponent<{
  value: string | string[];
  fieldName: string;
  placeholder: string;
  data: any[];
  valueField: string;
  textField: string;
  loading: boolean;
  onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  multiple: boolean;
}> = ({
  value,
  fieldName,
  placeholder,
  data,
  valueField,
  textField,
  loading,
  onChange,
  multiple
}) => {
  const options = React.useMemo(
    () =>
      data && data.length > 0
        ? data.map((opt, idx) => ({
            key: `${fieldName}_${idx}`,
            value: opt[valueField],
            text: fusionFields(textField)(opt)
          }))
        : [],
    [data]
  );

  return (
    <Dropdown
      {...{
        className: 'mb-3',
        placeholder,
        fluid: true,
        lazyLoad: true,
        search: true,
        selection: true,
        clearable: true,
        options,
        onChange,
        value,
        loading,
        disabled: loading,
        multiple
      }}
    />
  );
};

const GenerarConteo: FunctionComponent<{ auth: AuthState }> = ({ auth }) => {
  const [initialDate, setInitialDate] = useState<string | null>(cleanTextDate(new Date()));
  const [endDate, setEndDate] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [isExporting, setIsExporting] = useState<boolean | null>(null);
  const consolidado = true;
  const [filterZones, setFilterZones] = useState<string[]>([]);
  const [selectedFilterZones, setSelectedFilterZones] = useState<string[]>([]);
  const [filterSku, setFilterSku] = useState<string[]>([]);
  const [selectedFilterSku, setSelectedFilterSku] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onDescargarReporteClick =
    (initialDate: string | null, endDate: string | null, consolidado: boolean) => async () => {
      try {
        if (!initialDate) {
          Swal.fire({
            icon: 'error',
            title: 'Debes seleccionar una fecha de inicio'
          });
          return;
        }

        setIsExporting(true);
        const res = await getUncatchEndpointData({
          endpoint: 'inventarioPallet/export/reporte',
          query: {
            initialDate,
            endDate,
            consolidado,
            nombreCentro: selectedFilterZones,
            material: selectedFilterSku
          }
        });

        if (res.length === 0) {
          setIsExporting(false);
          return warningPopAlert(`No se encontraron registros que exportar`);
        }

        const exportableColumns = consolidado
          ? exportableColumnsConsolidatedReport
          : exportableReportColumns;

        const data = convertArrayOfObjectToFieldsMatrix(
          res.map((row: any) =>
            exportableColumns.reduce((acc, obj) => {
              if (row.hasOwnProperty(obj.selector)) {
                const key = obj.selector as string;
                acc[key] = obj.valueToExport ? obj.valueToExport(row) : row[key];
              }
              return acc;
            }, {} as Record<string, any>)
          ),
          exportableColumns.map((c) => c.selector),
          exportableColumns.map((c) => c.name)
        );

        const fileName = consolidado
          ? `Documento-Ajuste-SAP-Pallet_${new Date().toISOString().split('T')[0]}.xlsx`
          : `Reporte-Conteo-Pallet_${new Date().toISOString().split('T')[0]}.xlsx`;

        downloadXLSX(
          data,
          exportableColumns.map((c) => c.columnType),
          fileName
        );

        setIsExporting(false);
      } catch (error) {
        console.log(error);
        requestErrorPopAlert(error);
        setIsExporting(false);
      }
    };

  const onGenerarConteoClick = (initialDate: string | null, endDate: string | null) => async () => {
    try {
      if (!initialDate) {
        Swal.fire({
          icon: 'error',
          title: 'Debes seleccionar una fecha de inicio'
        });
        return;
      }

      setIsLoading(true);

      await performUncatchedRequest({
        endpoint: 'inventarioPallet/generarConteo',
        method: 'post',
        query: { initialDate, endDate }
      });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      requestErrorPopAlert(error);
      setIsLoading(false);
    }
  };

  //check for incorrect start and end dates
  useEffect(() => {
    const initialDateFormat = initialDate ? new Date(initialDate) : null;
    const endDateFormat = endDate ? new Date(endDate) : null;
    if (!initialDateFormat && endDateFormat) {
      setInitialDate(endDate);
    } else if (initialDateFormat && endDateFormat && endDateFormat < initialDateFormat) {
      setEndDate(initialDate);
    }
  }, [initialDate, endDate]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setFilterZones(
        await getEntity('reportes/getFilterZones', {
          query: { idTipoDocumento: IdTipoDocumentoValues.idConteoPallet }
        })
      );
      setFilterSku(
        await getEntity('reportes/getFilterSku', {
          query: { idTipoDocumento: IdTipoDocumentoValues.idConteoPallet }
        })
      );
      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <div className='pop-up-container'>
        <div className='pop-up-container-title'>
          <h3 className='pop-up-generar-conteo-title'>
            <strong className='pop-up-generar-conteo-title-strong'>REPORTE</strong>
          </h3>
        </div>
        <div className='pop-up-container-date'>
          <label className='pop-up-date-title' style={{ width: '100%' }}>
            Fecha Inicio
          </label>
          <DateTimePickerComponent
            name={'Fecha Inicio'}
            value={initialDate}
            onChange={setInitialDate}
            format='dd/MM/yyyy'
            timezone='America/Santiago'
          />
          <label className='pop-up-date-title' style={{ width: '100%' }}>
            Fecha Fin
          </label>
          <DateTimePickerComponent
            name={'Fecha Fin'}
            value={endDate}
            onChange={setEndDate}
            format='dd/MM/yyyy'
            timezone='America/Santiago'
          />
          {(auth.user?.nombreTipoUsuario === TipoUsuarioValues.Admin ||
            auth.user?.nombreTipoUsuario === TipoUsuarioValues.Visualizador) && (
            <>
              <label className='pop-up-date-title' style={{ width: '100%' }}>
                Zona
              </label>
              <GenericDropDownList
                placeholder={'Seleccione Zonas'}
                fieldName={'nombreCentro'}
                data={filterZones}
                onChange={(_: any, data: any) => setSelectedFilterZones(data.value)}
                valueField={'nombreCentro'}
                textField={'nombreCentro'}
                value={selectedFilterZones}
                loading={loading}
                multiple={true}
              />
              <label className='pop-up-date-title' style={{ width: '100%' }}>
                SKU
              </label>
              <GenericDropDownList
                placeholder={'Seleccione Sku'}
                fieldName={'material'}
                data={filterSku}
                onChange={(_: any, data: any) => setSelectedFilterSku(data.value)}
                valueField={'material'}
                textField={'material'}
                value={selectedFilterSku}
                loading={loading}
                multiple={true}
              />
            </>
          )}
        </div>

        <button className='btn-pop-up-generic' onClick={onGenerarConteoClick(initialDate, endDate)}>
          Generar Reporte
        </button>
        <button
          className='btn-pop-up-generic'
          onClick={onDescargarReporteClick(initialDate, endDate, !consolidado)}>
          Exportar Conteo Pallet
        </button>
        <button
          className='btn-pop-up-generic'
          onClick={onDescargarReporteClick(initialDate, endDate, consolidado)}>
          Documento Ajuste SAP
        </button>
        {isLoading === true && <span className='loader'></span>}
        {isLoading === false && <h1>Generado!</h1>}
        {isExporting === true && <h1>Exportando...</h1>}
        {/* {isLoading === false && resultGenerateConteo && <LogText>{resultGenerateConteo}</LogText>} */}
      </div>
    </div>
  );
};

const WelcomePage: FunctionComponent<
  IProps & {
    ingresarInventario: (
      inventarioEntity: SimpleObject,
      documentoEntity: SimpleObject,
      inventarioCriticidad: Array<Inventario>,
      date: Date,
      zona: string,
      callback: (res: AxiosResponse<ProcessProgressData> | null) => void,
      idComentario: string
    ) => Promise<any>;
  }
> = ({ ingresarInventario, auth }) => {
  const [date, setDate] = useState<string | null>(cleanTextDate(new Date()));
  const [comentarios, setComentarios] = useState<SimpleObject[]>([]);
  const [selectedComentario, setSelectedComentario] = useState<string>('');
  const [selectedZone, setSelectedZone] = useState<string>('');
  const [zones, setZones] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { user } = auth;
  const allowedUserTypes = [
    TipoUsuarioValues.Admin as string,
    TipoUsuarioValues.Operador as string
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [progressInfo, setProgressInfo] = useState<string>('');
  const [progressPercentage, setProgressPercentage] = useState<number>(0);

  const CargaInventarioBody = () => {
    if (allowedUserTypes.includes(user?.nombreTipoUsuario || '')) {
      return (
        <div>
          <GenericModal
            title='Carga de Inventario'
            buttonName='CARGAR CONTEO MASIVO'
            buttonStyle={CCUThemeButtonCargarInventario}
            icon='update'
            localIcon={true}
            openHook={() => setOpenModal(true)}
            closeHook={() => setOpenModal(false)}
            openModal={openModal}>
            <div>
              <Label>Fecha de Recuento</Label>
              <DateTimePickerComponent
                name={'Fecha'}
                value={date}
                onChange={setDate}
                format='dd/MM/yyyy'
                timezone='America/Santiago'
              />
              <div style={{ marginLeft: '1em', display: 'inline-block' }}>
                <Label>Zona</Label>
                <div style={{ display: 'inline-block' }}>
                  <GenericDropDownList
                    placeholder={'Seleccione Zona'}
                    fieldName={'zone'}
                    data={zones}
                    onChange={onChangeZone}
                    value={selectedZone}
                    valueField={'nombreCentro'}
                    textField={'nombreCentro'}
                    loading={loading}
                    multiple={false}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <Label>Archivo CSV</Label>
                  </div>
                  <div>
                    <Label>Comentario</Label>
                    <div style={{ display: 'inline-block' }}>
                      <GenericDropDownList
                        placeholder={'Seleccione Comentario'}
                        fieldName={'comentario'}
                        data={comentarios}
                        onChange={onChangeComentario}
                        value={selectedComentario}
                        valueField={'comentario'}
                        textField={'comentario'}
                        loading={loading}
                        multiple={false}
                      />
                    </div>
                  </div>
                </div>
                <FileReaderCSV
                  columns={COLUMNS}
                  callback={callback(date)}
                  dataInputName={NameConteoValues.conteoPallet}
                />
              </div>
              {progressInfo && <h3>{progressInfo}</h3>}
              {progressPercentage !== 0 && (
                <ProgressBar
                  animated
                  variant='success'
                  now={progressPercentage}
                  label={`${progressPercentage.toFixed(2)}%`}
                  visuallyHidden
                />
              )}
            </div>
          </GenericModal>
        </div>
      );
    }
    return null;
  };

  const onChangeZone = (_: any, data: any) => {
    setSelectedZone(data.value);
  };

  const onChangeComentario = (_: any, data: any) => {
    setSelectedComentario(data.value);
  };

  const callback = (date: string | null) => async (data: any) => {
    if (!selectedZone) {
      Swal.fire({
        icon: 'error',
        title: 'Debes seleccionar una zona'
      });
      return;
    } else if (!data) {
      Swal.fire({
        icon: 'error',
        title: 'Debes cargar un documento de conteo'
      });
      return;
    } else if (!date) {
      Swal.fire({
        icon: 'error',
        title: 'Debes seleccionar una fecha'
      });
      return;
    } else if (!selectedComentario) {
      Swal.fire({
        icon: 'error',
        title: 'Debes seleccionar un comentario'
      });
      return;
    }

    setProgressInfo('Enviando datos al servidor...');
    const comentario = comentarios.find((row) => row.comentario === selectedComentario);

    await ingresarInventario(
      E.InventarioPalletEntity,
      E.DocumentoPalletEntity,
      data,
      new Date(date),
      selectedZone,
      (res: AxiosResponse<ProcessProgressData> | null) => {
        if (res === null) {
          setProgressInfo('');
          setProgressPercentage(0);
          setOpenModal(false);
          return;
        }

        if (!res.data) {
          console.log('No se pudo obtener el progresso de la carga.');
          setProgressInfo('');
          setProgressPercentage(0);
          return;
        }

        if (res.data.done) {
          console.log(res.data);
          setProgressInfo('');
          setProgressPercentage(0);

          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '¡Documento ingresado!',
              text: `Se registro con éxito el documento con ID: ${res.data?.data.idDocumento}`
            });
            setOpenModal(false);
          }
          return;
        }

        console.log(res.data);
        setProgressInfo(`${res.data.message} (${Math.round(res.data.percentage)}%)`);
        setProgressPercentage(Math.round(res.data.percentage));
      },
      comentario?.idComentario
    );
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setZones(await getEntity('centroInventario/zonas'));
      setComentarios(
        await getEntity(`comentario/tipoDocumento/${IdTipoDocumentoValues.idConteoPallet}`)
      );
      setLoading(false);
    })();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: 15 }}>
      <h2 className='Conteo-Pallets-title'>
        Conteo <strong className='Conteo-Pallets-title-strong'>Pallets</strong>
      </h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', gap: 10 }}>
          {CargaInventarioBody()}
          <GenericModal
            buttonName='GENERAR REPORTE'
            buttonStyle={CCUThemeButtonCargarConteo}
            localIcon={true}
            icon='table'
            modalStyle={{
              width: 'auto',
              height: 'auto',
              top: 'auto',
              left: 'auto',
              overflow: 'visible'
            }}>
            <GenerarConteo auth={auth} />
          </GenericModal>
        </div>
        <div
          style={{
            backgroundColor: ' red',
            borderRadius: '5px',
            border: ' 2px solid var(--gray-5, #E0E0E0)',
            background: 'var(--blanco, #FFF)',
            boxShadow: '4px 5px 7px 0px #D5D6D7',
            padding: 5,
            width: '100%'
          }}>
          <DocumentoPalletVisualizer />
        </div>
        <div
          style={{
            backgroundColor: ' red',
            borderRadius: '5px',
            border: ' 2px solid var(--gray-5, #64A70B)',
            background: 'var(--blanco, #FFF)',
            boxShadow: '4px 5px 7px 0px #D5D6D7',
            padding: 5,
            width: '100%'
          }}>
          <div
            style={{
              marginLeft: 20,
              marginTop: 20
            }}>
            <ConnectedRefreshPallet />
          </div>
          <InventarioPalletDependent />
        </div>
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }), { ingresarInventario })(WelcomePage);
