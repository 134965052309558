import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store';
import FileReaderCSV, { FileReaderColumnProps } from '../components/FileReader';
import { errorPopAlert } from '../components/PopAlert';
import {
  mergeSku,
  perfilEnvejecimientoMassiveLoad,
  diasVentaMassiveLoad
} from '../actions/middleActions';
import { SKU_COLUMNS } from '../entities/SKUEntity';
import { PE_COLUMNS } from '../entities/PerfilEnvejecimientoEntity';
import { DV_COLUMNS } from '../entities/DiasVentaEntity';
import GenericModal from '../components/generics/GenericModal';
import { CCUThemeButtonCargarInventario } from '../components/generics/GenericThemes';
import {
  ActivosCRUD,
  CentroInventarioCRUD,
  ComentarioCRUD,
  DiasVentaCRUD,
  EstadoDocumentoCRUD,
  MaterialNomenclaturaCRUD,
  NomenclaturasCRUD,
  PerfilEnvejecimientoCRUD,
  RelacionEnvaseCasilleroCRUD,
  SkuCRUD,
  SkuFactorCRUD,
  TipoActivosCRUD,
  TipoUsuarioCRUD,
  UsuarioCRUD,
  ZonasOperadoresCRUD
} from '../components/GeneratedComponents';
import GenericAlerts, { InfoTagSizeValues } from '../components/generics/GenericInfoTag';

export type SpecialMaintainerComponentProps = {
  fileReaderCallback: (data: Array<any>) => Promise<any>;
  fileReaderColumns: FileReaderColumnProps;
  componentToRender: JSX.Element;
  buttonName?: string;
  size?: string;
  title?: string;
};

export const SpecialMaintainersComponent: FunctionComponent<SpecialMaintainerComponentProps> = ({
  fileReaderCallback,
  fileReaderColumns,
  componentToRender,
  buttonName,
  size,
  title
}) => {
  const uploadFile = async (data: any) => {
    if (!data) {
      errorPopAlert(`Debes cargar un documento`);
      return;
    }

    const res = await fileReaderCallback(data);

    if (res && res.status === 200) {
      alert(`Se actualizaron los datos exitosamente!`);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 15
      }}>
      <div>
        <GenericAlerts
          icon='info'
          title={title}
          strongTitle='Importante!'
          typeInfo={
            size === InfoTagSizeValues.notbg ? InfoTagSizeValues.notbg : InfoTagSizeValues.bg //default BIG
          }
        />
        <GenericModal
          title='Carga de Inventario'
          buttonStyle={{ ...CCUThemeButtonCargarInventario }}
          buttonName={buttonName ? buttonName : 'PROCESAR ARCHIVO'}
          icon='update'
          localIcon={true}>
          <FileReaderCSV columns={fileReaderColumns} callback={uploadFile} />
        </GenericModal>
      </div>
      <div
        style={{
          backgroundColor: ' red',
          borderRadius: '5px',
          border: ' 2px solid var(--gray-5, #E0E0E0)',
          background: 'var(--blanco, #FFF)',
          boxShadow: '4px 5px 7px 0px #D5D6D7',
          padding: 20,
          width: '100%',
          marginTop: 15
        }}>
        {componentToRender}
      </div>
    </div>
  );
};

const generateConnectedSpecialMaintainersComponent = (fileReaderCallback: any) =>
  connect(({ auth }: AppState) => ({ auth }), {
    fileReaderCallback
  })(SpecialMaintainersComponent);

const PreSkuMaintainer = generateConnectedSpecialMaintainersComponent(mergeSku);
const PreDiasVentaMaintainer = generateConnectedSpecialMaintainersComponent(diasVentaMassiveLoad);
const PrePerfilEnvejecimientoMaintainer = generateConnectedSpecialMaintainersComponent(
  perfilEnvejecimientoMassiveLoad
);

export const SkuMaintainer = () => (
  <PreSkuMaintainer
    {...{
      fileReaderColumns: SKU_COLUMNS,
      componentToRender: <SkuCRUD />,
      buttonName: 'CARGA MASIVA SKU',
      size: InfoTagSizeValues.bg,
      title:
        'Al subir un archivo, se actualizará la información de los datos existentes y se añadirá la información adicional.'
    }}
  />
);
export const DiasVentaMaintainer = () => (
  <PreDiasVentaMaintainer
    {...{
      fileReaderColumns: DV_COLUMNS,
      componentToRender: <DiasVentaCRUD />,
      buttonName: 'CARGA MASIVA DIAS VENTAS',
      size: InfoTagSizeValues.bg,
      title:
        'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
    }}
  />
);
export const PerfilEnvejecimientoMaintainer = () => (
  <PrePerfilEnvejecimientoMaintainer
    {...{
      fileReaderColumns: PE_COLUMNS,
      componentToRender: <PerfilEnvejecimientoCRUD />,
      buttonName: 'CARGA MASIVA PERFIL ENVEJECIMIENTO',
      size: InfoTagSizeValues.bg,
      title:
        'Al subir un archivo, se eliminará la información de los datos existentes y se reemplazará por nueva la información.'
    }}
  />
);

export const UsuarioCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <UsuarioCRUD />
    </div>
  );
};

export const ActivosCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ActivosCRUD />
    </div>
  );
};

export const TipoUsuarioCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <TipoUsuarioCRUD />
    </div>
  );
};

export const TipoActivosCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <TipoActivosCRUD />
    </div>
  );
};

export const SkuFactorCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <SkuFactorCRUD />
    </div>
  );
};

export const ComentarioMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ComentarioCRUD />
    </div>
  );
};

export const MaterialNomenclaturaMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <MaterialNomenclaturaCRUD />
    </div>
  );
};

export const RelacionEnvaseCasilleroCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <RelacionEnvaseCasilleroCRUD />
    </div>
  );
};

export const NomenclaturasCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <NomenclaturasCRUD />
    </div>
  );
};

export const EstadoDocumentoCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <EstadoDocumentoCRUD />
    </div>
  );
};

export const CentroInventarioCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <CentroInventarioCRUD />
    </div>
  );
};

export const ZonasOperadoresCRUDMaintainer = () => {
  return (
    <div
      style={{
        backgroundColor: ' red',
        borderRadius: '5px',
        border: ' 2px solid var(--gray-5, #E0E0E0)',
        background: 'var(--blanco, #FFF)',
        boxShadow: '4px 5px 7px 0px #D5D6D7',
        padding: 20,
        width: '100%'
      }}>
      <ZonasOperadoresCRUD />
    </div>
  );
};
