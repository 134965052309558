import { MaterialNomenclatura, TipoActivoValues } from '../types';
import { FieldTypes, WebEntity } from './types';

const MaterialNomenclaturaEntity: WebEntity<MaterialNomenclatura> = {
  name: 'materialNomenclatura',
  endpoint: 'materialNomenclatura',
  referenceColumn: 'material',
  tableColumns: [
    {
      name: 'ID',
      selector: 'idMaterialNomenclatura',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Sku',
      selector: 'material',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Descripción',
      selector: 'textoBreveMaterial',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Estado',
      selector: 'nomenclaturasAsignadas',
      columnType: 'text',
      wrap: true,
      format: ({ nomenclaturasAsignadas }) => nomenclaturasAsignadas?.join(', ').toString() || ''
    }
  ],

  fields: [
    {
      name: 'Sku',
      selector: 'material',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'material',
        endpoint: `sku/getDistinctMaterial/${TipoActivoValues.envaseCasillero}`,
        select: 'material',
        show: 'material'
      }
    },
    {
      name: 'Estado',
      selector: 'nomenclaturasAsignadas',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'descripcionNomenclatura',
        endpoint: `nomenclaturas/idTipoActivo/${TipoActivoValues.envaseCasillero}`,
        select: 'idNomenclatura',
        show: 'descripcionNomenclatura'
      }
    }
  ],

  editableFields: [
    {
      name: 'Sku',
      selector: 'material',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Estado',
      selector: 'nomenclaturasAsignadas',
      type: FieldTypes.MultiReference,
      required: true,
      reference: {
        name: 'descripcionNomenclatura',
        endpoint: `nomenclaturas/idTipoActivo/${TipoActivoValues.envaseCasillero}`,
        select: 'descripcionNomenclatura',
        show: 'descripcionNomenclatura'
      }
    }
  ]
};

export default MaterialNomenclaturaEntity;
