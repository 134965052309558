import { EstadoDocumento } from '../types';
import { FieldTypes, WebEntity } from './types';

const EstadoDocumentoEntity: WebEntity<EstadoDocumento> = {
  name: 'estadoDocumento',
  endpoint: 'estadoDocumento',
  referenceColumn: 'descripcionEstadoDocumento',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Descripcion',
      selector: 'descripcionEstadoDocumento',
      columnType: 'text',
      sortable: true
    }
  ],

  fields: [
    {
      name: 'Descipcion',
      selector: 'descipcionEstadoDocumento',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Descipcion',
      selector: 'descipcionEstadoDocumento',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export default EstadoDocumentoEntity;
