import { Inventario } from '../types';
import { dateFormatFn } from '../utils/utils';
import { FieldTypes, WebEntity } from './types';

const InventarioEnvasesEntity: WebEntity<Inventario> = {
  name: 'inventarioEnvases',
  endpoint: 'inventarioEnvases',
  referenceColumn: 'material',

  tableColumns: [
    {
      name: 'ID',
      selector: 'id',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'ID Documento',
      selector: 'idDocumento',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Material',
      selector: 'material',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Descripción',
      selector: 'textoBreveMaterial',
      columnType: 'text',
      sortable: true,
      wrap: true,
      /*conditionalCellStyles: [ TODO: 06-11-23
        {
          when: (row) => row,
          style: {
            width: '120rem',
            padding: 5
          }
        }
      ],*/
      center: true
    },
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      columnType: 'dateUTC',
      format: ({ fechaRegistro }) => dateFormatFn(fechaRegistro, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Utilizado',
      selector: 'tipoStock',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Usuario',
      selector: 'nombreUsuario',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Lote',
      selector: 'lote',
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    },
    {
      name: 'Pedido Cliente',
      selector: 'pedidoCliente',
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    },
    {
      name: 'Numero Hu',
      selector: 'numeroHu',
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    }
  ],

  fields: [],

  editableFields: [
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Utilizado',
      selector: 'tipoStock',
      type: FieldTypes.Reference,
      reference: {
        name: 'descripcionNomenclatura',
        endpoint: 'nomenclaturas/envases',
        select: 'descripcionNomenclatura',
        show: 'descripcionNomenclatura',
        endpointQuery: ({ material }) => ({ material })
      }
    }
  ]
};

export default InventarioEnvasesEntity;
