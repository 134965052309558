import React, { FunctionComponent, Fragment } from 'react';

/**
 * Format an string given as a children element.
 * This early implementation replaces the '\n' substring with html break line tags <br />.
 */
const Formatter: FunctionComponent<{ children: string }> = ({ children }) => {
  return (
    <>
      {children.split('\n').map((t, idx) => (
        <Fragment key={idx}>
          {t}
          <br />
        </Fragment>
      ))}
    </>
  );
};

export default Formatter;
