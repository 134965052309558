import { ReporteVencimiento } from '../../types';
import { FieldTypes, WebEntity } from '../types';
import { formatStringNumber } from '../../utils/utils';
import { updateFechaInventarioDependency } from './sharedComponents';

const monthGenerator = (cantidadDeMesesAdicionales: number): string => {
  // TODO: que la fecha no sea la actual, sino que la fechaInventario seleccionada en los filtros
  const current = new Date();

  const month = current.getMonth() + cantidadDeMesesAdicionales + 1; // Agregar 1 porque los meses comienzan en 0
  const year = current.getFullYear();
  return `${month.toString().padStart(2, '0')}-${year.toString()}`;
};

const ReporteVencimientoEntity: WebEntity<ReporteVencimiento> = {
  name: 'reporteVencimiento',
  endpoint: 'reportes',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'Id',
      selector: '_id',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Orden',
      selector: 'orden',
      columnType: 'text',
      omitExport: true,
      wrap: true,
      omit: true
    },
    {
      name: 'Numero Antiguo Material',
      selector: 'numeroAntiguoMaterial',
      columnType: 'text',
      valueToExport: ({ numeroAntiguoMaterial, orden }) =>
        numeroAntiguoMaterial || (orden > 0 ? 'TOTAL GENERAL' : ''),
      format: ({ numeroAntiguoMaterial, orden }) =>
        numeroAntiguoMaterial || (orden > 0 ? 'TOTAL GENERAL' : ''),
      wrap: true,
      sortable: true
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      columnType: 'text',
      valueToExport: ({ textoBreveMaterial, orden }) =>
        textoBreveMaterial || (orden > 0 ? 'TOTAL GENERAL' : ''),
      format: ({ textoBreveMaterial, orden }) =>
        textoBreveMaterial || (orden > 0 ? 'TOTAL GENERAL' : ''),
      wrap: true,
      sortable: true
    },
    {
      name: 'Zona',
      selector: 'zona',
      columnType: 'text',
      wrap: true,
      sortable: true
    },
    {
      name: `${monthGenerator(1)}`,
      selector: 'm01',
      columnType: 'numeric',
      format: ({ m01 }) => formatStringNumber(m01, 0, 0),
      sortable: true
    },
    {
      name: `${monthGenerator(2)}`,
      selector: 'm02',
      columnType: 'numeric',
      format: ({ m02 }) => formatStringNumber(m02, 0, 0),
      sortable: true
    },
    {
      name: `${monthGenerator(3)}`,
      selector: 'm03',
      columnType: 'numeric',
      format: ({ m03 }) => formatStringNumber(m03, 0, 0),
      sortable: true
    },
    {
      name: `${monthGenerator(4)}`,
      selector: 'm04',
      columnType: 'numeric',
      format: ({ m04 }) => formatStringNumber(m04, 0, 0),
      sortable: true
    },
    {
      name: 'Total General',
      selector: 'totalGeneral',
      columnType: 'numeric',
      format: ({ totalGeneral }) => formatStringNumber(totalGeneral, 0, 0),
      sortable: true
    }
  ],

  fields: [],
  editableFields: [],
  filterFields: [
    {
      name: 'Estado',
      selector: 'estado',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'estado',
        select: 'estado',
        show: 'nombre',
        data: [
          {
            estado: 'vencido',
            nombre: 'VENCIDO'
          },
          {
            estado: 'vencera',
            nombre: 'VENCERA'
          },
          {
            estado: 'todos',
            nombre: 'TODOS'
          }
        ]
      }
    },
    {
      name: 'Fecha Inventario',
      selector: 'fechaInventario',
      required: true,
      type: FieldTypes.Date,
      timezone: 'America/Santiago',
      updateDependency: updateFechaInventarioDependency
    },
    {
      name: 'Numero Antiguo Material',
      selector: 'numeroAntiguoMaterial',
      type: FieldTypes.MultiReference,
      required: false,
      reference: {
        name: 'numeroAntiguoMaterial',
        endpoint: 'reportes/filtros/numeroAntiguoMaterial',
        select: 'sku',
        show: 'sku'
      }
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Reference,
      required: false,
      reference: {
        name: 'textoBreveMaterial',
        endpoint: 'reportes/filtros/textoBreveMaterial',
        select: 'descripcionProducto',
        show: 'descripcionProducto'
      }
    },
    {
      name: 'Grupo Producto',
      selector: 'grupoProducto',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'grupoProducto',
        endpoint: 'reportes/filtros/grupoDeProducto',
        select: 'grupoProducto',
        show: 'grupoProducto',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'CD',
      selector: 'nombreCentro',
      required: true,
      type: FieldTypes.MultiReference,
      reference: {
        name: 'cd',
        endpoint: 'reportes/filtros/cd',
        select: 'nombreCentro',
        show: 'nombreCentro',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Marca',
      selector: 'marca',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'marca',
        endpoint: 'reportes/filtros/grupoDeMarca',
        select: 'marca',
        show: 'marca',
        filter: {
          activo: true
        }
      }
    },
    {
      name: 'Zonas',
      selector: 'zona',
      required: false,
      type: FieldTypes.MultiReference,
      reference: {
        name: 'zona',
        endpoint: 'reportes/filtros/zona',
        select: 'zona',
        show: 'zona'
      }
    },
    {
      name: 'Vence',
      selector: 'vencePerfilEnvejecimiento',
      required: false,
      type: FieldTypes.Reference,
      reference: {
        name: 'vencePerfilEnvejecimiento',
        select: 'value',
        show: 'option',
        data: [
          { option: 'SI', value: 'SI' },
          { option: 'NO', value: 'NO' }
        ]
      }
    }
  ]
};

export default ReporteVencimientoEntity;
