import { Inventario } from '../types';
import { dateFormatFn } from '../utils/utils';
import { getUncatchEndpointData } from '../utils/request';
import { FieldTypes, WebEntity, UpdateDependencyFunc } from './types';

const updateDependency =
  (
    fieldName: string,
    fieldToUpdate: 'fechaProduccion' | 'fechaVencimiento'
  ): UpdateDependencyFunc =>
  async (fieldValue, getField, setLoadings, _, updateFieldValue, getFieldValue): Promise<void> => {
    try {
      // we gonna modify fechaVencimiento given the fechaProduccion date
      const field = getField(fieldToUpdate);
      setLoadings([field], true);
      const material = getFieldValue('material');

      const { fecha: fechaStr } = await getUncatchEndpointData({
        endpoint: 'inventarioCriticidad/recalculate/fechaProdVenc',
        query: {
          fecha: fieldValue,
          material,
          isFechaProduccion: fieldName === 'fechaProduccion' ? true : false
        }
      });
      const fecha = new Date(fechaStr);
      updateFieldValue(fieldToUpdate, fecha);

      setLoadings([field], false);
    } catch (error) {
      console.log(error);
    }
  };

const InventarioCriticidadEntity: WebEntity<Inventario> = {
  name: 'inventarioCriticidad',
  endpoint: 'inventarioCriticidad',
  referenceColumn: 'material',

  tableColumns: [
    {
      name: 'ID',
      selector: 'id',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'ID Documento',
      selector: 'idDocumento',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      columnType: 'text',
      sortable: true,
      wrap: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Material',
      selector: 'material',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Descripción',
      selector: 'textoBreveMaterial',
      columnType: 'text',
      sortable: true,
      wrap: true,
      /*conditionalCellStyles: [ TODO: 06-11-23
        {
          when: (row) => row,
          style: {
            width: '120rem',
            padding: 5
          }
        }
      ],*/
      center: true
    },
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Fecha Produccion ',
      selector: 'fechaProduccion',
      columnType: 'dateUTC',
      format: ({ fechaProduccion }) => dateFormatFn(fechaProduccion, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      columnType: 'dateUTC',
      format: ({ fechaVencimiento }) => dateFormatFn(fechaVencimiento, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Unidad Cantidad Contada',
      selector: 'unidadCantidadContada',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Fecha Registro',
      selector: 'fechaRegistro',
      columnType: 'dateUTC',
      format: ({ fechaRegistro }) => dateFormatFn(fechaRegistro, 'DD/MM/YYYY', true),
      sortable: true,
      wrap: true
    },
    {
      name: 'Usuario',
      selector: 'nombreUsuario',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      columnType: 'text',
      sortable: true
    },
    {
      name: 'Lote',
      selector: 'lote',
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    },
    {
      name: 'Tipo Stock',
      selector: 'tipoStock',
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    },
    {
      name: 'Pedido Cliente',
      selector: 'pedidoCliente',
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    },
    {
      name: 'Numero Hu',
      selector: 'numeroHu',
      columnType: 'text',
      omitExport: true,
      sortable: true,
      omit: true
    }
  ],

  fields: [],

  editableFields: [
    {
      name: 'Cantidad Contada',
      selector: 'cantidadContada',
      type: FieldTypes.Text
    },
    {
      name: 'Bodega',
      selector: 'bodega',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Centro',
      selector: 'idCentro',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Almacen',
      selector: 'almacen',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      disabled: true
    },
    {
      name: 'Fecha Produccion',
      selector: 'fechaProduccion',
      type: FieldTypes.Date,
      updateDependency: updateDependency('fechaProduccion', 'fechaVencimiento')
    },
    {
      name: 'Fecha Vencimiento',
      selector: 'fechaVencimiento',
      type: FieldTypes.Date,
      updateDependency: updateDependency('fechaVencimiento', 'fechaProduccion')
    }
  ]
};

export default InventarioCriticidadEntity;
