import { Activo } from '../types';
import { FieldTypes, WebEntity } from './types';

const ActivoEntity: WebEntity<Activo> = {
  name: 'activo',
  endpoint: 'activos',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: 'idActivo',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Tipo Material',
      selector: 'tipoMaterial',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Tipo Activo',
      selector: 'descripcionTipoActivo',
      columnType: 'text',
      wrap: true
    }
  ],

  fields: [
    {
      name: 'Tipo Material',
      selector: 'tipoMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tipo Activo',
      selector: 'idTipoActivo',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'tipoUsuario',
        endpoint: 'tipoActivos',
        select: '_id',
        show: 'descripcionTipoActivo',
        filter: {
          activo: true
        }
      }
    }
  ],

  editableFields: [
    {
      name: 'ID Activo',
      selector: 'idActivo',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Tipo Material',
      selector: 'tipoMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Tipo Activo',
      selector: 'idTipoActivo',
      type: FieldTypes.Reference,
      required: true,
      reference: {
        name: 'tipoActivo',
        endpoint: 'tipoActivos',
        select: 'idTipoActivo',
        show: 'descripcionTipoActivo',
        filter: {
          activo: true
        }
      }
    }
  ]
};

export default ActivoEntity;
