import { TipoUsuario } from '../types';
import { FieldTypes, WebEntity } from './types';
import { booleanFormatFn } from '../utils/utils';

const TipoUsuarioEntity: WebEntity<TipoUsuario> = {
  name: 'tipoUsuario',
  endpoint: 'tipoUsuarios',
  referenceColumn: 'nombreTipoUsuario',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Nombre',
      selector: 'nombreTipoUsuario',
      columnType: 'text',
      sortable: true
    },
    {
      name: '¿Activo?',
      selector: 'activo',
      columnType: 'text',
      valueToExport: ({ activo }) => booleanFormatFn(activo),
      format: ({ activo }) => booleanFormatFn(activo),
      sortable: true
    }
  ],

  fields: [
    {
      name: 'Nombre',
      selector: 'nombre',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: []
};

export default TipoUsuarioEntity;
